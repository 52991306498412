<template>
  <div class="ParkInformation-container">
    <CSTabBar
      :tabbar="tabBarList"
      :checkedTab="tabBarType"
      @changeTabBar="changeTabBar"
    ></CSTabBar>
    <template v-if="tabBarType == 0">
        <Notive></Notive>
    </template>
    <template v-if="tabBarType == 1">
        <Journalism></Journalism>
    </template>
  </div>
</template>

<script>
import CSTabBar from "@/components/common/CSTabBar";
import setUps from "./setUp.vue";
import Notive from "./Notice.vue";
import Journalism from "./Journalism.vue";
export default {
  data() {
    return {
      tabBarType: 0,
      tabBarList: {
        0: "公告",
        1: "新闻"
      },
    };
  },
  methods: {
    changeTabBar(type) {
      this.tabBarType = type;
    },
  },
  components: {
    setUps,
    Notive,
    Journalism,
    CSTabBar,
  },
};
</script>

<style scoped>
</style>